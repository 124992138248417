(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var angular = require('angular'),
    uibs = require('angular-ui-bootstrap'),
    uiMask = require('angular-ui-mask'),
    router = require('angular-route'),
    ngSanitize = require('angular-sanitize'),
    ngAnimate = require('angular-animate'),
    ngMessages = require('angular-messages'),
    ngCookies = require('angular-cookies'),
    ngLocker = require('angular-locker'),
    bsLoadingOverlay = require('angular-loading-overlay'),
    bsLoadingOverlaySpinJs = require('angular-loading-overlay-spinjs');

}).call(this,require("wweLnl"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_c3851e49.js","/")